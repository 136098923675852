/*global google*/
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import React, { useRef, useState, useEffect, Fragment } from "react";


import {slide as Menu} from "react-burger-menu";
import "./MobileSidebar.css";

// import { mail } from "react-bootstrap-icons";

import {
  CDBSidebar,
  CDBSidebarContent,
  CDBSidebarFooter,
  CDBSidebarHeader,
  CDBSidebarMenu,
  CDBSidebarMenuItem,
} from "cdbreact";
import { NavLink } from "react-router-dom";
import { isMobile, BrowserView, MobileView } from "react-device-detect";

import logo from "./logo.png"
import saralam from "./saralam.jpg"
import { HiOutlineMail } from 'react-icons/hi';
import { BiPhoneCall, BiMoney } from "react-icons/bi";
import { GrDirections } from "react-icons/gr";
import { FaRoad, FaEye } from "react-icons/fa";
import { TiInfoLarge } from "react-icons/ti";
import { CiMoneyCheck1 } from "react-icons/ci";

import { PiMoney } from "react-icons/pi";

// import { BiMoney } from 'react-icons/bi';

//import 'semantic-ui-css/semantic.min.css'

import Testmap from './Testmap';
import Testmaptwo from './Testmaptwo';
import { Button } from 'react-bootstrap';


import Mapone from "./Mapone";
import Maponetwo from "./Maponetwo";

import Maptwo from "./Maptwo";
import Maptwotwo from "./Maptwotwo";

import Alert from './Alert';
import Alerttwo from './Alerttwo';



import Mobilealert from './Mobilealert';
import Mobilealerttwo from './Mobilealerttwo';

import Purchaseoptions from './Purchaseoptions';
import Purchaseoptionstwo from './Purchaseoptionstwo';

import Mobilepurchaseoptions from "./Mobilepurchaseoptions";
import Mobilepurchaseoptionstwo from "./Mobilepurchaseoptionstwo";

import Bot from "./Bot";
import { Modal } from 'react-bootstrap';

import AiBtn from '@aiui/ai-btn';

import { FaComments } from 'react-icons/fa';

import { FaRobot } from 'react-icons/fa';

import MobileBot from './MobileBot';


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
// import { FaMoon, FaSun } from "react-icons/fa";
// import { FaRegSun } from "react-icons/fa6";
import { FaSun } from "react-icons/fa";
import { FaMoon } from "react-icons/fa6";

import { Rnd } from 'react-rnd';


import { FaMinus, FaCaretUp } from 'react-icons/fa';

import { FiMinimize } from "react-icons/fi";
import { IoIosExpand } from "react-icons/io";

import { IoIosClose } from "react-icons/io";





function RefreshPage() {
  window.location.reload(false);
}



function SimpleMap() {

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Create a state for the menu
  const closeMenu = () => {
    setIsMenuOpen(false);
  };



  //bot

  const [showBot, setShowBot] = useState(false);
  

  const toggleBot = () => {
  setShowBot(!showBot);

  closeMenu(); // Close the menu when the bot is toggled


  // scroll entire page to the top
  window.scrollTo(0, 0);

  setIsShown(!isShown);
  setIsMinimized(false);

  }


  //cust

  const [isOpen, setIsOpen] = useState(false);

  const [isShown, setIsShown] = useState(false);


  const [isMinimized, setIsMinimized] = useState(false);
  const toggleMinimize = () => setIsMinimized(!isMinimized);
  const modalRef = useRef();

  const handleBlur = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.relatedTarget)) {
      setIsMinimized(true);
    }
  };

  const handleFocus = () => {
    setIsMinimized(false);
  };


  const handleHide = () => {
    setIsMinimized(true);
  };

  // const handleClose = () => { //uncomment this to make backdrop close
  //   setIsShown(false);
  // };

  const handleClose = () => {  //for make backdrop minimize
    setIsMinimized(true);
  };
  
  const handleMinimize = () => {
    setIsMinimized(!isMinimized);
  };

  //mobile modal
  const [isMobileMinimized, setIsMobileMinimized] = useState(false);

const handleMobileMinimize = () => {
  setIsMobileMinimized(true);
};

const handleMobileMaximize = () => {
  setIsMobileMinimized(false);
};

const [visible, setVisible] = useState(false); //true




  //cust end


  // dark mode test 

  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(prevDarkMode => {
      const nextDarkMode = !prevDarkMode;
      if (nextDarkMode) {
        document.body.classList.add('dark-mode');
        document.body.classList.remove('light-mode');
      } else {
        document.body.classList.add('light-mode');
        document.body.classList.remove('dark-mode');
      }
      return nextDarkMode;
    });
  };


  //dark mode test end
  


  //bot end

  const [mapd, setMapd] = useState(0);

  const [tabled, setTabled] = useState(0);

  const [purchased, setPurchased] = useState(0);




  let browser;

  if (isMobile) {
    browser = "mobile"
  } else {
    browser = "desktop"
  }

  // let mapd = 0;


 return (
  <>


  {/* MOBILE */}

  {browser=="mobile" ?
    
    <div className="App" id="outer-container">
      
     <Menu width={ '30%' } isOpen={isMenuOpen} onStateChange={({ isOpen }) => setIsMenuOpen(isOpen)}>    
     {/* <a href="https://www.saralam.tech"> <img src={saralam} alt="saralam" border="0" width={"40px"}/> </a> */}

      {/* Mobile info */}

      {tabled==0 && mapd==0 ? 
      (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled + 1)}/>)
      :
      tabled==0 && mapd==1 ? 
      (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled + 1)}/>)
      :
    tabled==0 && mapd==2 ?
      (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled + 2)}/>)
      :
    tabled==0 && mapd==3 ?
      (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled + 2)}/>)
      :
     tabled==1  ?
     (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled - 1)}/>)
      :
    tabled==2  ? 
    (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled - 2)}/>)  
     :
     (<TiInfoLarge width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setTabled(tabled / 0)}/>)
}



      {/* Project Mobile*/}
     <br/><br/>
        {mapd==0 ?
        (<FaEye width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd + 2)}/>)
        : mapd==1 ?
        (<FaEye width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd + 1)}/>)
        : mapd==2 ?
        (<FaEye width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd - 2)}/>)
        : mapd==3 ?
        (<FaEye width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd - 1)}/>)
        : 
        (<FaEye width={"25px"} size={"30px"} style={{ color: "black" }} onClick={RefreshPage}/>)
        }



        {/* Purchase Options Mobile*/}
        <br/><br/>
        {purchased==0 && mapd==0 ? 
      (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased + 1)}/>)
      :
      purchased==0 && mapd==1 ? 
      (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased + 1)}/>)
      :
      purchased==0 && mapd==2 ?
      (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased + 2)}/>)
      :
    purchased==0 && mapd==3 ?
      (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased + 2)}/>)
      :
     purchased==1  ?
     (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased - 1)}/>)
      :
    purchased==2  ? 
    (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased - 2)}/>)  
     :
     (<PiMoney width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setPurchased(purchased / 0)}/>)
}



        {/*Distance Mobile*/}
        <br/><br/>
        {mapd==1 ?
        (<FaRoad width={"25px"} size={"30px"} style={{ color: "black" }} onClick={Testmap}/>)
        : mapd==2 ?
        (<FaRoad width={"25px"} size={"30px"} style={{ color: "black" }} onClick={Testmaptwo}/>)
        :(<FaRoad width={"25px"} size={"30px"} style={{ color: "black" }} onClick={Testmap}/>)
        }
        <br/><br/>

        {/*Route Mobile*/}
        {mapd==0 ?
        (<GrDirections width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd + 1)}/>)
        : mapd==1 ?
        (<GrDirections width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd - 1)}/>)
        : mapd==2 ?
        (<GrDirections width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd + 1)}/>)
        : mapd==3 ?
        (<GrDirections width={"25px"} size={"30px"} style={{ color: "black" }} onClick={() => setMapd(mapd - 1)}/>)
        :
        <GrDirections width={"25px"} size={"30px"} style={{ color: "black" }} onClick={RefreshPage}/>
        }

        {/*Contact Info Mobile*/}
        <br/><br/>
     <a href="mailto:contact@saralam.tech"> <HiOutlineMail width={"25px"} size={"30px"} style={{ color: "black" }} /> </a>
        <br/><br/>
     <a href="tel:+914048597477"> <BiPhoneCall width={"25px"} size={"30px"} style={{ color: "black" }} /> </a>


{/* Mobile Bot here */}

<br/><br/>

{/* <FaRobot width={"25px"} size={"30px"} style={{ color: "black" }} onClick={handleShow} /> */}



{/* <FaComments width={"25px"} size={"30px"} style={{ color: "black" }}  onClick={toggleBot} /> */}
<FaComments 
  width={"25px"} 
  size={"30px"} 
  style={{ color: "black" }}  
  onClick={() => {
    toggleBot();
    setVisible(true);
  }}
/>


{/* Mobile Bot Modal */}
{/* <Modal 
  show={showBot} 
  onHide={toggleBot} 
  centered
  size="lg"
  style={{overflow:'auto'}}
  backdrop='static'

> 
  <Modal.Header closeButton>
    <Modal.Title>Thirdeye Bot</Modal.Title>
  </Modal.Header>
  <Modal.Body style={{ 
    maxHeight: 'calc(100vh - 210px)', 
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
    
  }}>
    <MobileBot/>
  </Modal.Body>
</Modal> */}


{/* Test */}
{/* <Modal 
  show={showBot} 
  onHide={toggleBot} 
  centered
  size="lg"
  style={{
    overflow:'auto',
    visibility: isMinimized ? 'hidden' : 'visible',
    transition: 'visibility 0.0s ease-in-out',
  }}
  backdrop='static'
> 
  <Modal.Header closeButton>
    <Modal.Title>Thirdeye Bot</Modal.Title>
    <div>
      {!isMinimized && 
        <IoIosClose 
          onClick={handleMinimize} 
          style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
        />
      }
    </div>
  </Modal.Header>
  <Modal.Body style={{ 
    maxHeight: 'calc(100vh - 210px)', 
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
    
  }}>
    <MobileBot/>
  </Modal.Body>
</Modal> */}

{/* Test 2 */}
{/* {isMobileMinimized ? (
  <div onClick={handleMobileMaximize} style={{ position: 'fixed', bottom: 0, right: 0, width: '100px', height: '30px', backgroundColor: '#f5f5f5', border: '1px solid #ccc', borderRadius: '13px' }}>
    <p>Open Bot</p>
  </div>
) : (
  <Modal 
    show={showBot} 
    onHide={toggleBot} 
    centered
    size="lg"
    style={{overflow:'auto'}}
    backdrop='static'
  > 
    <Modal.Header closeButton>
      <Modal.Title>Thirdeye Bot</Modal.Title>
      <div>
        <IoIosClose 
          onClick={handleMobileMinimize} 
          style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
        />
      </div>
    </Modal.Header>
    <Modal.Body style={{ 
      maxHeight: 'calc(100vh - 210px)', 
      overflowY: 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end'
      
    }}>
      <MobileBot/>
    </Modal.Body>
  </Modal>
)} */}

{/* Test 3 */}
{/* <Modal 
  show={showBot} 
  onHide={toggleBot} 
  centered
  size="lg"
  style={{
    overflow:'auto',
    visibility: isMinimized ? 'hidden' : 'visible',
    transition: 'visibility 0.0s ease-in-out',
  }}
  backdrop={isMinimized ? 'false' : 'static'}
> 
  <Modal.Header closeButton>
    <Modal.Title>Thirdeye Bot</Modal.Title>
    <div>
      {!isMinimized && 
        <IoIosClose 
          onClick={handleMinimize} 
          style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
        />
      }
    </div>
  </Modal.Header>
  <Modal.Body style={{ 
    maxHeight: 'calc(100vh - 210px)', 
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
    
  }}>
    <MobileBot/>
  </Modal.Body>
</Modal> */}

{/* Test 4 */}
{/* <Modal 
  show={showBot} 
  onHide={toggleBot} 
  centered
  size="lg"
  style={{
    overflow:'auto',
    visibility: isMinimized ? 'hidden' : 'visible',
    transition: 'visibility 0.0s ease-in-out',
  }}
  backdrop={isMinimized ? false : 'static'}
> 
  <Modal.Header closeButton>
    <Modal.Title>Thirdeye Bot</Modal.Title>
    <div>
      {!isMinimized && 
        <IoIosClose 
          onClick={handleMinimize} 
          style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
        />
      }
    </div>
  </Modal.Header>
  <Modal.Body style={{ 
    maxHeight: 'calc(100vh - 210px)', 
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
    
  }}>
    <MobileBot/>
  </Modal.Body>
</Modal> */}

{/* Test 5 working */}
{/* <Modal 
  show={visible} 
  onHide={() => setVisible(false)} 
  centered
  size="lg"
  style={{
    overflow:'auto',
    visibility: isMinimized ? 'hidden' : 'visible',
    transition: 'visibility 0.0s ease-in-out',
  }}
  backdrop={isMinimized ? false : 'static'}
> 
  <Modal.Header closeButton>
    <Modal.Title>Thirdeye Bot</Modal.Title>
    <div>
      {!isMinimized && 
        <IoIosClose 
          onClick={() => {
            handleMinimize();
            setVisible(true);
          }} 
          style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
        />
      }
    </div>
  </Modal.Header>
  <Modal.Body style={{ 
    maxHeight: 'calc(100vh - 210px)', 
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
    
  }}>
    <MobileBot/>
  </Modal.Body>
</Modal> */}

{/* Test 6 */}
{/* <Modal 
  show={visible} 
  onHide={() => setVisible(false)} 
  centered
  size="lg"
  style={{
    overflow:'auto',
    visibility: isMinimized ? 'hidden' : 'visible',
    transition: 'visibility 0.0s ease-in-out',
  }}
  backdrop={isMinimized ? false : 'static'}
> 
  <Modal.Header>
    <Modal.Title>Thirdeye Bot</Modal.Title>
    <div style={{ marginLeft: 'auto' }}>
      <IoIosClose 
        onClick={() => {
          handleMinimize();
          setVisible(true);
        }} 
        style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
      />
    </div>
  </Modal.Header>
  <Modal.Body style={{ 
    maxHeight: 'calc(100vh - 210px)', 
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
    
  }}>
    <MobileBot/>
  </Modal.Body>
</Modal> */}

{/* Test 7 */}
{/* <Modal 
  show={visible} 
  onHide={() => setVisible(false)} 
  centered
  size="lg"
  style={{
    overflow:'auto',
    visibility: isMinimized ? 'hidden' : 'visible',
    transition: 'visibility 0.0s ease-in-out',
  }}
  backdrop={isMinimized ? false : 'static'}
> 
  <Modal.Header>
    <Modal.Title>Thirdeye Bot</Modal.Title>
    <div style={{ marginLeft: 'auto' }}>
      <IoIosClose 
        onClick={() => {
          handleMinimize();
          setVisible(true);
        }} 
        style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
      />
    </div>
  </Modal.Header>
  <Modal.Body style={{ 
    maxHeight: 'calc(100vh - 210px)', 
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
    
  }}>
    <MobileBot/>
  </Modal.Body>
</Modal> */}

{/* Test 8 */}
<Modal 
  show={visible} 
  onHide={() => setVisible(false)} 
  centered
  size="lg"
  style={{
    overflow:'auto',
    visibility: isMinimized ? 'hidden' : 'visible',
    transition: 'visibility 0.0s ease-in-out',
  }}
  backdrop={isMinimized ? false : 'static'}
> 
  <Modal.Header>
    <Modal.Title>Thirdeye Bot</Modal.Title>
    <div style={{ marginLeft: 'auto' }}>
      <IoIosClose 
        onClick={() => {
          handleMinimize();
          setVisible(true);
        }} 
        style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
      />
    </div>
  </Modal.Header>
  <Modal.Body style={{ 
    maxHeight: 'calc(100vh - 210px)', 
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end'
    
  }}>
    <MobileBot/>
  </Modal.Body>
</Modal>


{/* Mobile Bot here end */}

     </Menu>

{/*Info Mobile*/}
<div style={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
{tabled==1 ? (
    <Mobilealert></Mobilealert>
  ) 
  :
  tabled==2 ? (
    <Mobilealerttwo></Mobilealerttwo>
  )
  : null}
</div>

{/*Purchase Options Mobile*/}
<div style={{ display:'flex', alignItems:'center', justifyContent:'center'}}>
{purchased==1 ? (
    <Mobilepurchaseoptions></Mobilepurchaseoptions>
  ) 
  :
  purchased==2 ? (
    <Mobilepurchaseoptionstwo></Mobilepurchaseoptionstwo>
  )
  : null}
</div>


  {/*Map Mobile*/}
  <div style={{ height: "100vh", width:"100%"}}>
  {mapd==0 ? (
<Mapone></Mapone>
) : mapd==1 ? (
<Maponetwo></Maponetwo>
) :  mapd==2 ? (
  <Maptwo></Maptwo> 
) : mapd==3 ? (
  <Maptwotwo></Maptwotwo>

) : null

}



{/* AI */}
<Button 
    onClick={() => {
      toggleBot();
      setVisible(true);
    }}
  style={{
    position: 'absolute', 
    right: '5px', 
    bottom: '60px', 
    zIndex: 1,
    width: '49px',
    height: '49px',
    backgroundColor: 'white',
    color: '#ff2000',
    border: 'none',
    outline: 'none'
  }}
>
  <FaComments /> 
</Button>



</div>
</div>
 : null} 
 


{/* DESKTOP */}
{browser=="desktop" ?


<Router>
  <div  style={{ display: 'flex', height: '100vh',  position: "inherit", overflow:"hidden", margin: "0 auto" }}>
    
<CDBSidebar  textColor="#333" backgroundColor="##f0f0f0" toggled="false" maxWidth='240px'> 

  <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large"></i>}>
  <img src={logo} alt="Logo" width={"70px"} align="middle"/> 
    <a
      href="/"
      className="text-decoration-none"
      style={{ color: 'inherit'}}
    >
      Thirdeye
    </a>
  </CDBSidebarHeader>



  {browser=="desktop" ?
  <CDBSidebarContent className="sidebar-content">
    <CDBSidebarMenu>
    
{/* Info Desktop */}
{tabled==0 && mapd==0 ? (
      <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled + 1)}>
      <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
      </NavLink> )
      :
      tabled==0 && mapd==1 ? (
        <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled + 1)}>
        <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
        </NavLink> )
        :
    tabled==0 && mapd==2 ? (
        <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled + 2)}>
        <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
        </NavLink> )
        :
    tabled==0 && mapd==3 ? (
          <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled + 2)}>
          <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
          </NavLink> )
        :
     tabled==1  ? (
                <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled - 1)}>
                <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
                </NavLink> )
              
        :
    tabled==2  ? (
          <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled - 2)}>
          <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
          </NavLink> )
        
        :
      <NavLink activeClassName="activeClicked" onClick={() => setTabled(tabled / 0)}>
      <CDBSidebarMenuItem icon="info-circle"> Info</CDBSidebarMenuItem>
      </NavLink>
}

     

      {/* <Button onClick={() => setTable(table + 1)}> Details </Button> */}



{/* {Project} */}
{mapd==0 ? (
    <NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 2)}>
    <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
  </NavLink> )
    : mapd==1 ? (
      <NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 1)}>
      <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
    </NavLink> )
  : mapd==2 ? (
    <NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd - 2)}>
    <CDBSidebarMenuItem icon="eye" >Project One</CDBSidebarMenuItem>
  </NavLink> )
    : mapd==3 ? (
      <NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd - 1)}>
      <CDBSidebarMenuItem icon="eye" >Project One</CDBSidebarMenuItem>
    </NavLink> )
  : 
  (<NavLink activeClassName="activeClicked" onClick={RefreshPage}>
  <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
</NavLink>) } 


{/* Purchase Options Desktop */}


{purchased==0 && mapd==0 ? (
      <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased + 1)}>
      <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
      </NavLink> )
      :
      purchased==0 && mapd==1 ? (
        <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased + 1)}>
        <CDBSidebarMenuItem icon="wallet"> Purchase Options</CDBSidebarMenuItem>
        </NavLink> )
        :
    purchased==0 && mapd==2 ? (
        <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased + 2)}>
        <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
        </NavLink> )
        :
    purchased==0 && mapd==3 ? (
          <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased + 2)}>
          <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
          </NavLink> )
        :
     purchased==1  ? (
                <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased - 1)}>
                <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
                </NavLink> )
              
        :
    purchased==2  ? (
          <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased - 2)}>
          <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
          </NavLink> )
        
  :
      <NavLink activeClassName="activeClicked" onClick={() => setPurchased(purchased / 0)}>
      <CDBSidebarMenuItem icon="wallet"> Purchase Options </CDBSidebarMenuItem>
      </NavLink>
}




{/* {Distance Desktop} */}
{mapd==0 || mapd==1 ?
      (<NavLink activeClassName="activeClicked" onClick={Testmap}>
        <CDBSidebarMenuItem icon="road" >Direction</CDBSidebarMenuItem>
      </NavLink>) 
: mapd==2 ||mapd==3 ?
(<NavLink activeClassName="activeClicked" onClick={Testmaptwo}>
<CDBSidebarMenuItem icon="road" >Direction</CDBSidebarMenuItem>
</NavLink>)
:       
(<NavLink activeClassName="activeClicked" onClick={Testmap}>       {/*null causes error*/}
<CDBSidebarMenuItem icon="road" >Direction</CDBSidebarMenuItem>
</NavLink>)
}



{/* {Route Desktop} */}
{mapd==0 ?
    (<NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 1)}>
    <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
  </NavLink>)
    : mapd==1 ?
    (<NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd - 1)}>
    <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
  </NavLink>)
  : mapd==2 ?
  (<NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 1)}>
  <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
</NavLink>)
  : mapd==3 ?
  (<NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd - 1)}>
  <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
</NavLink>)
  :
null } 



      {/*Contact Desktop*/}

      <NavLink exact to="mailto:contact@saralam.tech" activeClassName="activeClicked">
        <CDBSidebarMenuItem icon="envelope">Email</CDBSidebarMenuItem>
      </NavLink>

      <NavLink exact to="tel:+914048597477" activeClassName="activeClicked">
        <CDBSidebarMenuItem icon="phone">Phone</CDBSidebarMenuItem>
      </NavLink>

{/* 
      <NavLink onClick={Bot} activeClassName="activeClicked">
        <CDBSidebarMenuItem icon="comment">AI</CDBSidebarMenuItem>
      </NavLink> */}

      {/* Bot start */}

<NavLink   onClick={() => {
    toggleBot();
    setVisible(true);
  }}  activeClassName="activeClicked">
    <CDBSidebarMenuItem icon="comment">Bot</CDBSidebarMenuItem>
  </NavLink>
   

  {/* <Modal dialogClassName="modal-90w" show={showBot} onHide={toggleBot} size="md" style={{overflow:'auto'}}>
  <Modal.Header closeButton>
    <Modal.Title className='modal-title'>Thirdeye Bot</Modal.Title>
  </Modal.Header>
        <Modal.Body className='modal-body-custom' style={{ maxHeight: 'auto', overflowY: 'auto', padding: '5px', overflow: 'hidden', paddingLeft: '1px' }}>
          <Bot />
        </Modal.Body>
      </Modal> */}


{/* <Modal dialogClassName="modal-90w" show={showBot} onHide={toggleBot} size="md" style={{overflow:'auto'}} backdrop='static'>
  <Modal.Header closeButton>
    <Modal.Title>Thirdeye Bot</Modal.Title>
  </Modal.Header>
  <Modal.Body className='modal-body-custom' style={{ maxHeight: 'auto', overflowY: 'auto', padding: '5px', overflow: 'hidden', paddingLeft: '1px' }}>
    <Bot />
  </Modal.Body>
</Modal> */}


{/* {isOpen && (
  <div className="pseudo-modal">
    <div className="pseudo-modal-content">
      <h2>Thirdeye Bot</h2>
      <div className='modal-body-custom' style={{ maxHeight: 'auto', overflowY: 'auto', padding: '5px', overflow: 'hidden', paddingLeft: '1px' }}>
        {Bot ? <Bot /> : <></>}
      </div>
      <button onClick={() => setIsOpen(false)}>Close</button>
    </div>
  </div>
)} */}


{/* <Modal
      dialogClassName={`modal-90w ${isMinimized ? 'minimized' : ''}`}
      show={showBot}
      onHide={toggleBot}
      size="md"
      style={{ overflow: 'auto' }}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Thirdeye Bot
          <button onClick={toggleMinimize}>
            {isMinimized ? '^' : '-'}
          </button>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        className='modal-body-custom'
        style={{
          maxHeight: 'auto',
          overflowY: 'auto',
          padding: '5px',
          overflow: 'hidden',
          paddingLeft: '1px'
        }}
      >
        <Bot />
      </Modal.Body>
    </Modal> */}


{/* <Modal dialogClassName={`modal-90w ${darkMode ? 'dark-mode' : 'light-mode'}`} show={showBot} onHide={toggleBot} size="md" style={{overflow:'auto'}}>
      <Modal.Header closeButton>
        <Modal.Title>
          Thirdeye Bot
        </Modal.Title>
        <div onClick={toggleDarkMode} style={{ marginLeft: '10px', cursor: 'pointer' }}>
          {darkMode ? <FaSun /> : <FaMoon />}
        </div>
      </Modal.Header>
      <Modal.Body className='modal-body-custom' style={{ maxHeight: 'auto', overflowY: 'auto', padding: '5px', overflow: 'hidden', paddingLeft: '1px' }}>
        <Bot />
      </Modal.Body>
    </Modal> */}


{/* <div style={{
      position: 'fixed',
      bottom: 0,
      right: 0,
      width: isMinimized ? '20px' : '300px',
      height: isMinimized ? '20px' : '600px',
      backgroundColor: '#f5f5f5',
      border: '1px solid #ccc',
      borderRadius: '10px',
      overflow: 'hidden',
    }}>
      <div style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
        <Button onClick={() => setIsMinimized(!isMinimized)}>
          {isMinimized ? 'Expand' : 'Minimize'}
        </Button>
      </div>
      {!isMinimized && <Bot />}
    </div> */}

{/* Working */}
{/* <div className='modal-90w' style={{
  position: 'fixed',
  bottom: 0,
  right: 0,
  width: isMinimized ? '20px' : '300px',
  height: isMinimized ? '20px' : '600px',
  backgroundColor: '#f5f5f5',
  border: '1px solid #ccc',
  borderRadius: '10px',
  overflow: 'hidden',
}}>
  <div style={{ padding: '10px', borderBottom: '1px solid #ccc', display: 'flex', justifyContent: 'space-between' }}>
    <h5>Thirdeye Bot</h5>
    <div>
      <FiMinimize 
        onClick={() => setIsMinimized(!isMinimized)} 
        style={{fontSize: '16px', cursor: 'pointer'}}
      > 
        {isMinimized ? 'Expand' : 'Minimize'} 
      </FiMinimize>
      {!isMinimized && 
        <IoIosClose 
          onClick={() => setIsMinimized(true)} 
          style={{fontSize: '24px', cursor: 'pointer'}}
        >
        </IoIosClose>
      }
    </div>
  </div>
  {!isMinimized && <Bot />}
</div> */}



        {/* <Rnd
      default={{
        x: window.innerWidth - 400,
        y: window.innerHeight - 600,
        width: 400,
        height: 600,
      }}
      minWidth={300}
      minHeight={200}
      bounds="window"
    >
      <div style={{
        width: '100%',
        height: '100%',
        backgroundColor: '#f5f5f5',
        border: '1px solid #ccc',
        borderRadius: '10px',
        overflow: 'hidden',
      }}>
        <div style={{ padding: '10px', borderBottom: '1px solid #ccc', display: 'flex', justifyContent: 'space-between' }}>
          <h5>Thirdeye Bot</h5>
          <div>
            <button onClick={() => setIsMinimized(!isMinimized)}>
              {isMinimized ? 'Expand' : 'Minimize'}
            </button>
            {!isMinimized && <button onClick={() => setIsMinimized(true)}>Close</button>}
          </div>
        </div>
        {!isMinimized && <Bot />}
      </div>
    </Rnd> */}

{/* <Modal 
  dialogClassName="modal-90w" 
  show={showBot} 
  onHide={toggleBot} 
  size="md" 
  style={{overflow:'auto', position: 'absolute'}}
  backdrop={false} // This will remove the backdrop
  keyboard={false} // This will prevent the modal from closing when escape key is pressed
  // style={{ position: 'absolute' }} // This will allow interaction with the rest of the page
>
  <Modal.Header closeButton>
    <Modal.Title className='modal-title'>Thirdeye Bot</Modal.Title>
  </Modal.Header>
  <Modal.Body className='modal-body-custom' style={{ maxHeight: 'auto', overflowY: 'auto', padding: '5px', overflow: 'hidden', paddingLeft: '1px' }}>
    <Bot />
  </Modal.Body>
</Modal> */}


{/* <Modal 
  dialogClassName={`modal-90w ${isMinimized ? 'minimized' : ''}`} 
  show={isShown} 
  onHide={handleClose} 
  size="md" 
  style={{overflow:'auto'}}
>
  <Modal.Header closeButton>
    <Modal.Title className='modal-title'>
      Thirdeye Bot
      <button onClick={handleMinimize}>
        {isMinimized ? <FaCaretUp /> : <FaMinus />}
      </button>
    </Modal.Title>
  </Modal.Header>
  {!isMinimized && (
    <Modal.Body className='modal-body-custom' style={{ maxHeight: 'auto', overflowY: 'auto', padding: '5px', overflow: 'hidden', paddingLeft: '1px' }}>
      <Bot />
    </Modal.Body>
  )}
</Modal> */}

{/* <Modal 
  dialogClassName={`modal-90w ${isMinimized ? 'minimized' : ''}`} 
  show={isShown} 
  onHide={handleClose} 
  size="md" 
  style={{overflow:'auto'}}
>
  <Modal.Header closeButton>
    <Modal.Title className='modal-title' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
      Thirdeye Bot

      <div onClick={toggleDarkMode} style={{ marginLeft: '90px', cursor: 'pointer' }}>
          {darkMode ? <FaSun /> : <FaMoon />}
        </div>
      <button className='modal-title' onClick={handleMinimize} style={{ border: 'none', background: 'transparent' }}>
        {isMinimized ? <IoIosExpand /> : <FiMinimize />}
      </button>
    </Modal.Title>
  </Modal.Header>
  {!isMinimized && (
    <Modal.Body className='modal-body-custom' style={{ maxHeight: 'auto', overflowY: 'auto', padding: '5px', overflow: 'hidden', paddingLeft: '1px' }}>
      <Bot />
    </Modal.Body>
  )}
</Modal> */}


{/* Working modal but not minimizing */}
{/* <div className='modal-90w' style={{
      position: 'fixed',
      bottom: 0,
      right: 0,
      width: isMinimized ? '20px' : '310px',
      height: isMinimized ? '20px' : '559px',
      backgroundColor: '#f5f5f5',
      border: '1px solid #ccc',
      borderRadius: '13px',
      overflow: 'hidden',
    }}>
      <div style={{ padding: '15px', borderBottom: '1px solid #ccc', display: 'flex', justifyContent: 'space-between' }}>
        <h5 className='modal-title' style={{ marginBottom: '2px' }}>Thirdeye Bot</h5>
        <div>
          <FiMinimize 
            onClick={handleMinimize} 
            style={{fontSize: '18px', cursor: 'pointer', color: '#888', ':hover': { color: 'blue' }}}
            
          /> 
          <span style={{ width: '20px' }} />
          {!isMinimized && 
            <IoIosClose 
              onClick={handleMinimize} 
              style={{fontSize: '30px', cursor: 'pointer', color: '#888', ':hover': { color: 'orangered' }}}
              
            />
          }
        </div>
      </div>
      {!isMinimized && <Bot />}
    </div> */}

{/* Working minimize minus one */}
<div 
  className='modal-90w' 
  style={{
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '310px',
    height: '530px',
    backgroundColor: '#f5f5f5',
    border: '1px solid #ccc',
    borderRadius: '13px',
    overflow: 'auto',
    visibility: isMinimized || !visible ? 'hidden' : 'visible',
    transition: 'visibility 0.0s ease-in-out',
  }}
>
  <div style={{ padding: '15px', borderBottom: '1px solid #ccc', display: 'flex', justifyContent: 'space-between', position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#f5f5f5' }}>
    <h5 className='modal-title' style={{ marginBottom: '2px' }}>Thirdeye Bot</h5>
    <div>
      <span style={{ width: '20px' }} /> 
      {!isMinimized && 
        <IoIosClose 
          onClick={() => {
            handleMinimize();
            setVisible(false);
          }} 
          style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
        />
      }
    </div>
  </div>
  <Bot />
</div>

    {/* Working minimize zero */}

    {/* <div 
  className='modal-90w' 
  style={{
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '310px',
    height: '530px',
    backgroundColor: '#f5f5f5',
    border: '1px solid #ccc',
    borderRadius: '13px',
    overflow: 'auto',
    visibility: isMinimized ? 'hidden' : 'visible',
    transition: 'visibility 0.0s ease-in-out',
  }}
>
  <div style={{ padding: '15px', borderBottom: '1px solid #ccc', display: 'flex', justifyContent: 'space-between', position: 'sticky', top: 0, zIndex: 1, backgroundColor: '#f5f5f5' }}>
    <h5 className='modal-title' style={{ marginBottom: '2px' }}>Thirdeye Bot</h5>
    <div> */}
      {/* <FiMinimize 
        onClick={handleMinimize} 
        style={{ fontSize: '18px', cursor: 'pointer', color: '#888' }}
      />  */}
      {/* <span style={{ width: '20px' }} /> 
      {!isMinimized && 
        <IoIosClose 
          onClick={handleMinimize} 
          style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
        />
      }
    </div>
  </div>
  <Bot />

</div> */}




{/* Working minimize one */}
    {/* <div 
      className='modal-90w' 
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '310px',
        height: '580px',
        backgroundColor: '#f5f5f5',
        border: '1px solid #ccc',
        borderRadius: '13px',
        overflow: 'auto',
        visibility: isMinimized ? 'hidden' : 'visible',
        transition: 'visibility 0.0s ease-in-out',
      }}
    >
      <div style={{ padding: '15px', borderBottom: '1px solid #ccc', display: 'flex', justifyContent: 'space-between' }}>
        <h5 className='modal-title' style={{ marginBottom: '2px' }}>Thirdeye Bot</h5>
        <div>
          <FiMinimize 
            onClick={handleMinimize} 
            style={{ fontSize: '18px', cursor: 'pointer', color: '#888' }}
          /> 
          <span style={{ width: '20px' }} /> 
          {!isMinimized && 
            <IoIosClose 
              onClick={handleClose} 
              style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
            />
          }
        </div>
      </div>
      <Bot />
    </div> */}



    {/* Working minimize */}
{/* <div 
      className='modal-90w' 
      style={{
        position: 'fixed',
        bottom: 0,
        right: 0,
        width: '310px',
        height: '559px',
        backgroundColor: '#f5f5f5',
        border: '1px solid #ccc',
        borderRadius: '13px',
        overflow: 'hidden',
        visibility: isMinimized ? 'hidden' : 'visible',
        transition: 'visibility 0.3s ease-in-out',
      }}
    >
      <div style={{ padding: '15px', borderBottom: '1px solid #ccc', display: 'flex', justifyContent: 'space-between' }}>
        <h5 className='modal-title' style={{ marginBottom: '2px' }}>Thirdeye Bot</h5>
        <div>
          <FiMinimize 
            onClick={handleMinimize} 
            style={{ fontSize: '18px', cursor: 'pointer', color: '#888' }}
          /> 
          <span style={{ width: '20px' }} />
          {!isMinimized && 
            <IoIosClose 
              onClick={handleClose} 
              style={{ fontSize: '30px', cursor: 'pointer', color: '#888' }}
            />
          }
        </div>
      </div>
      <Bot />
    </div> */}
  
  


{/* <div>
      <NavLink onClick={toggleBot} activeClassName="activeClicked">
        <CDBSidebarMenuItem icon="comment">Bot</CDBSidebarMenuItem>
      </NavLink>
      {showBot && (
        <div className='modal-90w' style={{
          position: 'fixed',
          bottom: 0,
          right: 0,
          width: isMinimized ? '20px' : '300px',
          height: isMinimized ? '20px' : '600px',
          backgroundColor: '#f5f5f5',
          border: '1px solid #ccc',
          borderRadius: '10px',
          overflow: 'hidden',
        }}>
          <div style={{ padding: '10px', borderBottom: '1px solid #ccc', display: 'flex', justifyContent: 'space-between' }}>
            <h5>Thirdeye Bot</h5>
            <div>
              <FiMinimize 
                onClick={handleMinimize} 
                style={{fontSize: '16px', cursor: 'pointer'}}
              /> 
              {!isMinimized && 
                <IoIosClose 
                  onClick={toggleBot} 
                  style={{fontSize: '24px', cursor: 'pointer'}}
                />
              }
            </div>
          </div>
          {!isMinimized && <Bot />}
        </div>
      )}
    </div> */}


    {/* Test */}

{/* <div className='modal-90w' style={{
      position: 'fixed',
      bottom: 0,
      right: 0,
      width: isMinimized ? '20px' : '310px',
      height: isMinimized ? '20px' : '559px',
      backgroundColor: '#f5f5f5',
      border: '1px solid #ccc',
      borderRadius: '13px',
      overflow: 'hidden',
    }}>
      <div style={{ padding: '15px', borderBottom: '1px solid #ccc', display: 'flex', justifyContent: 'space-between' }}>
        <h5 className='modal-title' style={{ marginBottom: '2px' }}>Thirdeye Bot</h5>
        <div>
          <FiMinimize 
            onClick={handleMinimize} 
            style={{fontSize: '18px', cursor: 'pointer', color: '#888', ':hover': { color: 'blue' }}}
            
          /> 
          <span style={{ width: '20px' }} />
          {!isMinimized && 
            <IoIosClose 
              onClick={handleMinimize} 
              style={{fontSize: '30px', cursor: 'pointer', color: '#888', ':hover': { color: 'orangered' }}}
              
            />
          }
        </div>
      </div>
      {!isMinimized && <Bot />}
    </div> */}



    



      
      
    </CDBSidebarMenu>
  </CDBSidebarContent>  : 
  <CDBSidebarContent className="sidebar-content">
  <CDBSidebarMenu>
  <img src={logo} alt="Logo" width={"80px"}/>
    <NavLink exact to="http://www.saralam.tech" activeClassName="activeClicked">
      <CDBSidebarMenuItem icon="home">Saralam</CDBSidebarMenuItem>
    </NavLink>
    {mapd==0 ? (
    <NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 2)}>
    <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
  </NavLink> )
    : mapd==1 ? (
      <NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd - 1)}>
      <CDBSidebarMenuItem icon="eye" >Project One</CDBSidebarMenuItem>
    </NavLink> )
  : mapd==2 ? (
    <NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 0)}>
    <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
  </NavLink> )
  : 
  (<NavLink activeClassName="activeClicked" onClick={RefreshPage}>
  <CDBSidebarMenuItem icon="eye" >Project Two</CDBSidebarMenuItem>
</NavLink> ) } 
    <NavLink activeClassName="activeClicked" onClick={Testmap}>
      <CDBSidebarMenuItem icon="road">Direction</CDBSidebarMenuItem>
    </NavLink>
    {mapd==0 ?
    (<NavLink activeClassName="activeClicked" onClick={() => setMapd(mapd + 1)}>
    <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
  </NavLink>)
  :
  (<NavLink activeClassName="activeClicked" onClick={RefreshPage}>
  <CDBSidebarMenuItem icon="map" >Route</CDBSidebarMenuItem>
</NavLink>) } 
    <NavLink exact to="mailto:contact@saralam.tech" activeClassName="activeClicked">
      <CDBSidebarMenuItem icon="envelope">Email</CDBSidebarMenuItem>
    </NavLink>
    <NavLink exact to="tel:+914048597477" activeClassName="activeClicked">
      <CDBSidebarMenuItem icon="phone">Phone</CDBSidebarMenuItem>
    </NavLink>
  </CDBSidebarMenu>
</CDBSidebarContent> }
  <CDBSidebarFooter style={{ textAlign: 'center' }}>
    <div
      className="sidebar-btn-wrapper"
      style={{
        padding: '20px 5px',
      }}
    >
      {/* Thirdeye Footer */}
    </div>
  </CDBSidebarFooter>
</CDBSidebar>

{/*Info Desktop*/}
<div style={{ height: "100vh", width:"100%"}}>

{
  tabled==1 ? (
    <Alert></Alert>
  ) 
  :
  tabled==2 ? (
    <Alerttwo></Alerttwo>
  )
  : null
}

{/*Purchase Options Desktop*/}
{
  purchased==1 ? (
    <Purchaseoptions></Purchaseoptions>
  ) 
  :
  purchased==2 ? (
    <Purchaseoptionstwo></Purchaseoptionstwo>
  )
  : null
}


{/*  Map Desktop */}
{mapd==0 ? (
<Mapone></Mapone>
) : mapd==1 ? (
<Maponetwo></Maponetwo>
) :  mapd==2 ? (
  <Maptwo></Maptwo> 
) : mapd==3 ? (
  <Maptwotwo></Maptwotwo>

) : null
}

{/* AI */}
<Button 
    onClick={() => {
      toggleBot();
      setVisible(true);
    }} 
  style={{
    position: 'absolute', 
    right: '5px', 
    bottom: '70px', 
    zIndex: 1,
    width: '49px',
    height: '49px',
    backgroundColor: 'white',
    color: '#ff2000',
    border: 'none',
    outline: 'none'
  }}
>
  <FaComments /> 
</Button>

{/*  Bot Desktop */}
 {/* {showBot && <Bot />}  */}



</div>
</div>
</Router>

: null} 

  
</>


  
          
  );
}

export default SimpleMap;