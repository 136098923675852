import React from 'react';
import ReactDOM from 'react-dom';
// import './Botstyles.css';
import logo from './images/thirdeye.png';
import { MDBInput } from 'mdb-react-ui-kit';
import TextBoxPlaceholder from 'textbox-placeholder'
import axios from 'axios';

import { Modal } from 'react-bootstrap';


import Speech from 'speak-tts';
import Brian from './images/brian.png'

import Speaker from './images/speaker-512.png'

import './MobileBotStyles.css';

class MobileBot extends React.Component {


    
  constructor(props) {
    super(props);
    this.state = {
      userMessages: [],
      botMessages: [],
      botGreeting: 'Hello, I\'m Thirdeye Bot. You can ask me anything',
      botLoading: false,
      suggestions: ['Enquire about Third Eye app', 'What is Western Country project', 'Who are Bashyam developers'],
      showSuggestions: true,
    };
  }

  updateUserMessages = async (newMessage) => {
    var updatedUserMessagesArr = this.state.userMessages;
    var updatedBotMessagesArr = this.state.botMessages;

    this.setState({
      userMessages: updatedUserMessagesArr.concat(newMessage),
      botLoading: true,
      showSuggestions: false,
    });

    if (!newMessage) {
      this.setState({
        botMessages: updatedBotMessagesArr.concat("There was an error processing your request."),
        botLoading: false,
      });
      return;
    }

    try {
      const response = await this.handleQuery(newMessage);
      const botResponse = response.data.answer;

      // simulate delay
      setTimeout(() => {
        this.setState({
          botMessages: updatedBotMessagesArr.concat(botResponse),
          botLoading: false,
        });
      }, 2000);
    } catch (error) {
      console.error('ERROR =>', error);
      // simulate a delay before error message
      setTimeout(() => {
        this.setState({
          botMessages: updatedBotMessagesArr.concat("There was an error processing your request."),
          botLoading: false,
        });
      }, 2000); // 2 secs
    }
  }

  handleQuery = async (query) => {
    const response = await axios.post('https://brmtools.cloud/aibot', { question: query }, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response;
  }

  render() {
    return (
      <div id="app-container" style={{marginBottom: '0px'}}>
        <div className="convo-container" style={{ maxHeight: '55vh', marginBottom: '0px'}}>
          <BotBubble message={this.state.botGreeting} key="bot-00" />
          {this.state.showSuggestions && this.state.suggestions.map((suggestion, index) => (
            <BotBubble message={suggestion} key={'suggestion' + index} handleClick={() => this.updateUserMessages(suggestion)} isSuggestion={true} />
          ))}
          {this.state.userMessages.map((message, index) => (
            <div className="conversation-pair" key={'convo' + index}>
              <UserBubble message={message} key={'u' + index} />
              <BotBubble message={this.state.botMessages[index]} key={'b' + index} />
            </div>
          ))}
        </div>
        <UserInput updateUserMessages={this.updateUserMessages} showSuggestions={this.state.showSuggestions} />
      </div>
    );
  }
}

class UserBubble extends React.Component {
  render() {
    return (
      <div className="user-message-container">
        <div className="chat-bubble user">{this.props.message}</div>
      </div>
    );
  }
}

class BotBubble extends React.Component {
  speech = new Speech();
  componentDidMount = () => {
    var lastBubble = this.refs.chatBubble;
    lastBubble.scrollIntoView(true);

    if (this.speech.hasBrowserSupport()) {
      this.speech.init({
        'volume': 1,
        'lang': 'en-GB',
        'rate': 1,
        'pitch': 1,
        'voice':'Google UK English Male',
        'splitSentences': true,
        'listeners': {
          'onvoiceschanged': (voices) => {
            console.log("Voice changed", voices)
          }
        }
      }).then((data) => {
        const voice = this.speech.getVoices().find(voice => voice.name === 'Brian');
        this.speech.setVoice(voice.name);
      }).catch(e => {
        console.error("An error occurred while initializing : ", e)
      });
    }
  }
  handleSpeak = () => {
    this.speech.speak({ text: this.props.message });
  }
  render() {
    return (
      <div className={`bot-message-container ${this.props.isSuggestion ? 'suggestion' : ''}`} onClick={this.props.handleClick}>
        <div className="img-avatar-container">
          <img className="bot-avatar" src={logo} alt="thirdeye-bot" />
        </div>
        <div className={`chat-bubble bot ${this.props.isSuggestion ? 'suggestion' : ''}`} ref="chatBubble">{this.props.message ? this.props.message : <div className="typing-indicator"><span></span><span></span><span></span></div>}</div>
        <button style={{border: 'none', background: 'none'}} onClick={this.handleSpeak} title="Brian TTS">
          <img src={Speaker} alt="TTS Icon" />
        </button>
      </div>
    );
  }
}

class UserInput extends React.Component {
  handleChange = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      var userInput = event.target.value;
      this.submitInput(userInput);
      event.target.value = '';
    }
  }

  handleClick = () => {
    var userInput = document.getElementById('chat').value;
    this.submitInput(userInput);
    document.getElementById('chat').value = '';
  }

  submitInput = (userInput) => {
    this.props.updateUserMessages(userInput);
  }

  render() {
    return (
      <div>
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center',width: '100%'}}>
          <input id="chat" type="text" onKeyPress={this.handleChange} placeholder="Type something" 
          style={{flexGrow: 1, marginRight: '10px', height: '40px', width: '85%'}} className="input-field" />
           <button style={{ 
  width: '12%', 
  height: '40px', 
  borderRadius: '50%', 
  backgroundColor: '#ff2000', 
  color: 'white', 
  border: 'none', 
  cursor: 'pointer' 
}} 
onClick={this.handleClick} >✎</button>
        </div>
      </div>
    );
  }
}

export default MobileBot;