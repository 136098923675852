import React, { useRef, useState, useEffect, Fragment } from "react";
import GoogleMapReact from "google-map-react";
import firebase from "./firebase";
import { BrowserRouter as Router } from 'react-router-dom';

function Maptwo() {

  const mapRef = useRef(null);
  const [mapData, setMapData] = useState(null);
  

  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const proxyGoogleMaps = await firebase
          .functions()
          .httpsCallable("proxyGoogleMaps");

        const { data } = await proxyGoogleMaps({
          url: "http://maps.googleapis.com/maps/api/js?libraries=geometry&sensor=false&",
        });
        setMapData(data);
      } catch (error) {
        console.error("Error fetching map data:", error);
      }
    };
    fetchMapData();
  }, []);

  useEffect(() => {
    if (setMapData) {
      const script = document.createElement("script");

      script.type = "text/javascript";
      script.innerHTML = mapData;
      console.log("script ", script);
      document.head.appendChild(script);
      return () => {
        document.head.removeChild(script);
      };
    }
  }, [mapData]);

  const defaultProps = {
    center: { lat: 17.644960, lng: 79.090119 },
    zoom: 18,
  };

  
const renderPolygon = (map, maps) => {
const rvhgoldenheights = [
 
    {lat: 17.640387, lng: 79.092057 },
    {lat: 17.641286, lng:79.091307 },
    {lat: 17.641962, lng:79.091611},
    {lat: 17.642893, lng:79.091737},
    {lat: 17.642986, lng: 79.090464},
    {lat: 17.644050, lng:79.090643},
    {lat: 17.643832, lng:79.089325},
    {lat: 17.644004, lng: 79.088396},
    {lat: 17.645577, lng: 79.088355},
    {lat: 17.646428, lng: 79.089321},
    {lat: 17.645618, lng:79.091611},
    {lat: 17.642907, lng:79.094618},
    
  ];
  
  const rvhgoldenheightslayout = new maps.Polygon({
    paths: rvhgoldenheights,
    strokeColor: "#FF0000",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "WHITE",
    fillOpacity: 0.4,
  });
  
  rvhgoldenheightslayout.setMap(map);
  
  const image =
      "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png";
  
  
  
   const mainFlag1 = new maps.Marker({
      position:  {lat: 17.640296, lng: 79.092006 },
       map,
      icon: image,
    });
  
    const mainFlag2 = new maps.Marker({
      position: {lat: 17.641236, lng:79.091174},
        map,
      icon: image,
    });
  
   const mainFlag3 = new maps.Marker({
      position: {lat: 17.641979, lng: 79.091550},
      map,
      icon: image,
    });
  
   const mainFlag4 = new maps.Marker({
      position: {lat: 17.642639, lng: 79.091656},
      map,
      icon: image,
    });
  
  const mainFlag5 = new maps.Marker({
      position: {lat: 17.642932, lng: 79.090402},
      map,
      icon: image,
    });
  
  //flags
  
  const mainFlag7 = new maps.Marker({
      position: {lat: 17.643976, lng:79.090542},
      map,
      icon: image,
    });
  
  const mainFlag8 = new maps.Marker({
      position: {lat: 17.643771, lng:79.089307},
      map,
      icon: image,
    });
  
  const mainFlag9 = new maps.Marker({
      position: {lat: 17.643976, lng:79.088338},
      map,
      icon: image,
    });
  
    
  const mainFlag10 = new maps.Marker({
      position: {lat: 17.644815, lng:79.088255},
      map,
      icon: image,
  });
    
  const mainFlag11 = new maps.Marker({
    position: {lat: 17.645463, lng:79.088353},
   map,
    icon: image,
  });
  
  const mainFlag12 = new maps.Marker({
    position: {lat: 17.646516, lng:79.089245},
   map,
    icon: image,
  });
  
  const mainFlag13 = new maps.Marker({
    position: {lat: 17.645739, lng:79.091664},
   map,
    icon: image,
  });
  const mainFlag14 = new maps.Marker({
    position: {lat: 17.643025, lng:79.094715},
   map,
    icon: image,
  });
  
  
  
  const mainrdc = [
  
    {lat: 17.642507, lng: 79.091655},
    {lat: 17.644124, lng:79.093461},
     {lat: 17.644036, lng: 79.093582},
    {lat: 17.642286, lng: 79.091678},
  
  
  
    ]
  
    const mainrd = new maps.Polygon({
      paths: mainrdc,
      strokeColor: "BLACK",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "BLACK",
      fillOpacity: 0.8,
    });
  
    mainrd.setMap(map);
  
    const main40ft = new maps.Marker({
      position: { lat: 17.643881, lng:79.093225}, 
  
  
      label: {text: "40ft", color: "red", fontSize:"10px"},
      icon: {
        path: maps.SymbolPath.CIRCLE,
        scale: 10,
        strokeWeight: 1,
        fillColor: 'WHITE',
        strokeColor: 'WHITE',
        fillOpacity: 1
      },
      map: map,
     title: "40ft wide road",
      optimized: false,
    });
  
   
    // main40ft.addListener("click", () => {
  
    //     infoWindow.close();
    //     infoWindow.setContent(main40ft.getTitle());
    //     infoWindow.open(main40ft.getMap(), main40ft);
    // });
  
    const maincrrdc = [
  
      {lat: 17.642659, lng: 79.092004},
      {lat: 17.641578, lng: 79.093310},
       {lat: 17.641709, lng: 79.093452},
      {lat: 17.642818, lng:  79.092176},
  
     
      ]
    
      const maincrrd = new maps.Polygon({
        paths: maincrrdc,
        strokeColor: "BLACK",
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: "BLACK",
        fillOpacity: 0.8,
      });
  
      maincrrd.setMap(map);
  
      const main130ft = new maps.Marker({
        position: { lat: 17.642428, lng:79.092525},
        label: {text: "60ft", color: "red", fontSize:"8px"},
        icon: {
          path: maps.SymbolPath.CIRCLE,
          scale: 10,
          strokeWeight: 1,
          fillColor: 'WHITE',
          strokeColor: 'WHITE',
          fillOpacity: 1
        },
        map: map,
       title: "Proposed 60ft wide road",
        optimized: false,
      });
    
      
     
    //   main130ft.addListener("click", () => {
    
    //       infoWindow.close();
    //       infoWindow.setContent(main130ft.getTitle());
    //       infoWindow.open(main130ft.getMap(), main130ft);
    //   });
  
  const maincrrdc2 = [
  {lat:17.645918, lng:  79.088784},
  {lat: 17.642994, lng: 79.092287},
  {lat: 17.643164, lng: 79.092403},
  {lat: 17.646070, lng:  79.088935},
  
  ]
  
  const maincrrd2 = new maps.Polygon({
    paths: maincrrdc2,
    strokeColor: "BLACK",
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: "BLACK",
    fillOpacity: 0.8,
  });
  
  maincrrd2.setMap(map);
  
  const main230ft = new maps.Marker({
    position: { lat: 17.645250, lng:79.089850},
  
    label: {text: "40ft", color: "red", fontSize:"8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 10,
      strokeWeight: 1,
      fillColor: 'WHITE',
      strokeColor: 'WHITE',
      fillOpacity: 1
    },
    map: map,
   title: "40ft wide road",
    optimized: false,
  });
  
    
  
  const park1c = [
  
    {lat: 17.642507, lng: 79.091655},
    {lat: 17.642994, lng: 79.092287},
    {lat: 17.643690, lng: 79.091473},
  
   
    {lat: 17.642980, lng: 79.090669},
    {lat: 17.642893, lng:79.091737},
  
    ]
  
  
  const park1 = new maps.Polygon({
    paths: park1c,
    strokeColor: "GREEN",
    strokeOpacity: 0.5,
    strokeWeight: 2,
    fillColor: "GREEN",
    fillOpacity: 0.5,
  });
  
  park1.setMap(map);
  
  const markPark1 = new maps.Marker({
    position: { lat: 17.643141, lng:79.091616 },
  
    label: {text: "Park Area", color: "white", fontSize: "8px"},
    icon: {
      path: maps.SymbolPath.CIRCLE,
      scale: 8,
      strokeWeight: 0,
      fillColor: 'RED',
      strokeColor: 'RED',
      fillOpacity: 0
    },
    map: map,
      title: "SocialInfra"
  });
        
  
  
  const park2c = [
    {lat: 17.644855, lng: 79.088338},   
    {lat: 17.643832, lng:79.089325},
    {lat: 17.644015, lng:79.088388},
   
    ]
  
  
    const park2 = new maps.Polygon({
      paths: park2c,
      strokeColor: "GREEN",
      strokeOpacity: 0.8,
      strokeWeight: 1,
      fillColor: "BROWN",
      fillOpacity: 0.8,
    });
  
    park2.setMap(map);
  
    const markPark2 = new maps.Marker({
      position: { lat: 17.644161, lng:79.088581}, 
      label: {text: "Open Space", color: "white", fontSize: "8px"},
      icon: {
        path: maps.SymbolPath.CIRCLE,
        scale: 8,
        strokeWeight: 0,
        fillColor: 'GREEN',
        strokeColor: 'GREEN',
        fillOpacity: 0
      },
      map: map,
        title: "Park Area"
    });
  
  
  const park3c = [
    {lat: 17.542135, lng:78.865104},  
    {lat: 17.542343, lng: 78.865102}, 
  {lat: 17.542344, lng: 78.865634},
  {lat: 17.541984, lng: 78.865638},
      
  ]
    
    

};



  return (

     
  
    <div style={{ height: "100vh", width: "100%",  position: "inherit", overflow:"hidden", margin: "0 auto" }}>
       <GoogleMapReact
       
        bootstrapURLKeys={{
          libraries: ["places", "geometry"],
          key: "AIzaSyB-IhLNuNEMTKd8VHG8Xyij2srPNVS_PR4",
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onGoogleApiLoaded={({ map, maps }) => renderPolygon(map, maps)}
        yesIWantToUseGoogleMapApiInternals
        options={{
          styles: [ { featureType: "all", elementType: "labels", stylers: [ { visibility:  "on", } ] } ],
          mapTypeControl: false,
          mapTypeId: "hybrid",
          gestureHandling: "greedy",
          mapTypeControl: false,

          streetViewControl: false,

          zoomControl: false,

          fullscreenControl: true,

          rotateControl: true,

          disableDefaultUI: false,

          panControl: true,

          scaleControl: true,

          streetViewControl: true,

          overviewMapControl: true,
        }}
        ref={mapRef}
      >
      </GoogleMapReact>
    </div>
  
);

}

export default Maptwo;