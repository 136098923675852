import React, { useState } from "react";
// import "./styles.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom"; 
import { Button } from 'react-bootstrap';

import Table from 'react-bootstrap/Table';

import "./Mobilestyles.css"

import { MDBCheckbox } from 'mdb-react-ui-kit';

const data = [
  {
    FullPayment: "Full Payment",
    EMI: "EMI",
    BankLoan: "Bank Loan",
    GroupPurchase: "Group Purchase"

  }

];

export default function Purchaseoptionstwo() {
  const [show, setShow] = useState(true);
  const [selectedData, setSelectedData] = useState({});
  const handleClick = (selectedRec) => {
    setSelectedData(selectedRec);
    setShow(true);
  };

  const hideModal = () => {
    setShow(false);
  };

  return (
    <div className="App">
      {/* {show && <Modal details={selectedData} handleClose={hideModal} />} */}
      { show && <Modal handleClose={hideModal} /> }
    </div>
  );
}

const Modal = ({ handleClose, details }) => {
  return (
    <div className="modal display-block">
      <section className="mobile-modal-main">
        <div className="App">
          <Table  striped bordered hover responsive className="tableclass">
            <thead>
              <tr className="trclass">
                <th scope="col">Full Payment</th>
                <th scope="col">EMI</th>
                <th scope="col">Bank Loan</th>
                <th scope="col">Group Purchase</th>
              </tr>
            </thead>
            <tbody>
              <tr className="trclass">

                {/* <td><input name="Full Payment" type="checkbox"  disabled="disabled" value="1" /></td>
                <td><input name="EMI" type="checkbox"  checked="checked" /></td>
                <td><input name="Bank Loan" type="checkbox" disabled="disabled" value="1" /></td>
                <td><input name="Group Purchase" type="checkbox" checked="checked" /></td> */}

                <td><MDBCheckbox name='Full Payment' value='' id='flexCheckChecked' disabled="disabled" /></td>
                <td><MDBCheckbox name='EMI' value='' id='flexCheckChecked' checked="checked" /></td>
                <td><MDBCheckbox name='Bank Loan' value='' id='flexCheckChecked' disabled="disabled"  /></td>
                <td><MDBCheckbox name='Group Purchases' value='' id='flexCheckChecked' checked="checked" /></td>



              </tr>
            </tbody>
          </Table>
        </div>
        {/* <Button type="button" class="btn btn-primary btn-sm" onClick={handleClose}>close</Button> */}
 
        <Button variant="secondary" size="sm" onClick={handleClose}>close</Button>
      </section>
    </div>
  );
};
