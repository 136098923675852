/*global google*/


import React, { Component } from "react";
import GoogleMapReact from "google-map-react";
//import "./style.css"

//const google = window.google;



function GoogleMaps() {


      const apiIsLoaded = (map, maps) => {

        const directionsService = new google.maps.DirectionsService();
        const directionsRenderer = new google.maps.DirectionsRenderer();
        const destinationB = { lat: 17.039614, lng: 78.573692 };
        directionsRenderer.setMap(map);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
              (position) => {
                const currentPos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                };
        
                const request = {
                  origin: currentPos,
                  destination: destinationB,
                  travelMode: google.maps.TravelMode.DRIVING,
                  unitSystem: google.maps.UnitSystem.METRIC,
                  avoidHighways: false,
                  avoidTolls: false,
                };
                directionsService.route(request, function(response, status) {
                  if (status == google.maps.DirectionsStatus.OK) {
                    console.log(response);
                    directionsRenderer.setDirections(response);
            
                  }
                });
       
        
              },
              () => {
                
        
              }
            );
          }

          //FROM HERE

          
            const path = [
              { lat: 17.040349, lng: 78.568842 },
              { lat: 17.039901, lng: 78.569857 },
              { lat: 17.038629, lng: 78.570385 },
              { lat: 17.038499, lng: 78.570696 },
              { lat: 17.038341, lng: 78.570534 },
              { lat: 17.038108, lng: 78.570038 },
              { lat: 17.039087, lng: 78.568342 },
              { lat: 17.040349, lng: 78.568842 },
            ];
        
            
            const polygon = new maps.Polygon({
              paths: path,
              strokeColor: "#000000",
              strokeOpacity: 0.8,
              strokeWeight: 2,
              // fillColor: "#FF0000",
              fillOpacity: 0.35,
            });
        
        
        
            polygon.setMap(map);
        
            const infoWindow = new maps.InfoWindow(); 
        
        
        
          const triangleCoords = [
            { lat: 17.2556277, lng: 78.1811585 },
            { lat: 17.2551274, lng: 78.1819092 },
            { lat: 17.2550986, lng: 78.1819319 },
            { lat: 17.2550730, lng: 78.1819070 },
            { lat :17.2547102, lng:78.1810345},
            {lat:17.2546710, lng:78.1810048},
            {lat:17.2548113, lng:78.1808341},
            {lat:17.2554932, lng:78.1808170},
              
          ];
        
           const survey135Cords = [
            { lat: 17.2550986, lng: 78.1819319 },
            { lat: 17.2545259, lng: 78.1827120 },
            { lat: 17.2544886, lng: 78.1826762 },
        
            { lat: 17.2540457, lng: 78.1824786 },
        
            { lat :17.2542303, lng:78.1820759},
        
            {lat:17.2542419, lng:78.1820364},
        
            {lat:17.2546710, lng:78.1810048},
              { lat :17.2547102, lng:78.1810345},
               { lat: 17.2550730, lng: 78.1819070 },
        
          ];
        
        const survey181Cords = [
        
          {lat: 17.2537642, lng: 78.1859797},
          {lat: 17.2548323, lng: 78.1858025},
          {lat: 17.2548448, lng: 78.1901538},
          {lat: 17.2535056, lng: 78.1909370},
                              
        
        ];
        
        const kurmidda = [
        
        {lat: 17.040349, lng: 78.568842},
        {lat: 17.039901, lng: 78.569857},
        {lat: 17.038629, lng: 78.570385},
        {lat: 17.038499, lng: 78.570696},
        {lat: 17.038341, lng: 78.570534},
        {lat: 17.038108, lng: 78.570038},
        {lat: 17.039087, lng: 78.568342},
        
        ];
        
        const kurmiddaleft = [
        
        {lat: 17.039806, lng: 78.569891},
        {lat: 17.038629, lng: 78.570385},
        {lat: 17.038499, lng: 78.570696},
        {lat: 17.038341, lng: 78.570534},
        {lat: 17.038108, lng: 78.570038},
        {lat: 17.039087, lng: 78.568342},
        {lat: 17.039899, lng: 78.568658},
        {lat: 17.039806, lng: 78.569891},
        
        ];
        
        
        
        const mainrd = [
        
        {lat: 17.039994, lng: 78.568699},
        {lat: 17.039960, lng: 78.568991},
        {lat: 17.039903, lng: 78.569864},
        {lat: 17.039806, lng: 78.569891},
        
        {lat: 17.039803, lng: 78.569731},
        {lat: 17.039835, lng: 78.569461},
        {lat: 17.039841, lng: 78.569376},
        {lat: 17.039863, lng: 78.569069},
        {lat: 17.039871, lng: 78.568985},
        {lat: 17.039899, lng: 78.568658}
        
        ]
        
        const rtmainrdc = [
        
        
        {lat: 17.039871, lng: 78.568986},
        {lat: 17.038766, lng: 78.568901},
        {lat: 17.038709, lng: 78.568990},
        {lat: 17.039868, lng: 78.569075}
        
        
        ]
        
        const ltmainrd2c = [
        
        {lat: 17.039841, lng:78.569379},
        {lat: 17.038536, lng: 78.569282},
        
        {lat: 17.038490, lng: 78.569360},
        {lat: 17.039834, lng: 78.569455}
        
        ]
        
        const ltmainrd3c = [
        
        {lat: 17.039812, lng: 78.569732},
        
        {lat: 17.038338, lng: 78.569633},
        
        {lat: 17.038292, lng: 78.569713},
        
        {lat: 17.039807, lng: 78.569818}
        
        ]
        
        const ltmainrd4c = [
        
        {lat: 17.039397, lng: 78.570069},
        
        {lat: 17.038138, lng: 78.569972},
        
        {lat: 17.038104, lng: 78.570038},
        {lat: 17.039232, lng: 78.570137}
        
        
        ]
        
        const first1c = [
        {lat:17.039630, lng: 78.568551},
        
        {lat: 17.039596, lng: 78.568961},
        
        {lat: 17.039515, lng: 78.568956},
        
        {lat: 17.039556, lng: 78.568520}
        ]
        
        
        
        const secondxc = [
        
        {lat: 17.039542, lng: 78.568597},
        {lat: 17.038969, lng: 78.568546},
        
        {lat: 17.038925, lng: 78.568622},
        
        {lat: 17.039541, lng: 78.568669}
        
        ]
        
        const thirdxc = [
        
        {lat: 17.038700, lng: 78.569371},
        
        {lat: 17.038626, lng: 78.570384},
        {lat: 17.038530, lng: 78.570606},
        {lat: 17.038631, lng: 78.569369}
        
        
        ]
        
        
        
        
        const leftrd1 = [
        
        {lat: 17.039960, lng: 78.568991},
        {lat: 17.040267, lng: 78.569014},
        {lat: 17.040229, lng: 78.569113},
        {lat: 17.039966, lng: 78.569092},
        
        ];
        
        
        const parkcords = [
        
        
        {lat: 17.040229, lng: 78.569113},
        {lat: 17.039966, lng: 78.569092},
        {lat: 17.039903, lng: 78.569864},
        
        ];
        
        const park2c = [
        
        {lat: 17.038124, lng: 78.570062},
        {lat: 17.038561, lng: 78.570088},
        
        {lat: 17.038525, lng: 78.570613},
        
        {lat: 17.038491, lng: 78.570699},
        
        {lat: 17.038345, lng: 78.570549}
        
        
        
        ]
        
        const plot101cords = [
        
        {lat: 17.040349, lng: 78.568842},
        {lat: 17.040267, lng: 78.569014},
        {lat: 17.040180, lng: 78.569009},
        {lat: 17.040215, lng: 78.568780},
        
        ];
        
        const plot100cords = [
        
        {lat: 17.040180, lng: 78.569009},
        {lat: 17.040103, lng: 78.569005},
        {lat: 17.040137, lng: 78.568746},
        {lat: 17.040215, lng: 78.568780},
        
        ];
        
        const plot98cords = [
        {lat: 17.040116, lng: 78.568863},
        {lat: 17.039978, lng: 78.568820},
        {lat: 17.039960, lng: 78.568991},
        {lat: 17.040103, lng: 78.569005},
        
        ];
        
        
        const plot99cords = [
        
        {lat: 17.040137, lng: 78.568746},
        {lat: 17.040116, lng: 78.568863},
        {lat: 17.039978, lng: 78.568820},
        {lat: 17.039994, lng: 78.568699},
        
        
        ];
        
        //FINE TILL HERE
        
        // Construct the polygon.
        const bermudaTriangle = new maps.Polygon({
          paths: triangleCoords,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
        
        const survey135 = new maps.Polygon({
          paths: survey135Cords,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
        
        
        
         const survey181 = new maps.Polygon({
          paths: survey181Cords,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0.35,
        });
        
        
        const kurmiddalayout = new maps.Polygon({
          paths: kurmidda,
          strokeColor: "#FF0000",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "#FF0000",
          fillOpacity: 0,
        });
        
        const kurmiddalayoutleft = new maps.Polygon({
          paths: kurmiddaleft,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "WHITE",
          fillOpacity: 1,
        });
        
         const kumiddamnrd = new maps.Polygon({
          paths: mainrd,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "BLACK",
          fillOpacity: 0.5,
        });
        
        const rtmainrd = new maps.Polygon({
          paths: rtmainrdc,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "BLACK",
          fillOpacity: 0.5,
        });
        
         
        
        const ltmainrd2 = new maps.Polygon({
          paths: ltmainrd2c,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "BLACK",
          fillOpacity: 0.5,
        });
        
        const ltmainrd3 = new maps.Polygon({
          paths: ltmainrd3c,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "BLACK",
          fillOpacity: 0.5,
        });
        
        const ltmainrd4 = new maps.Polygon({
          paths: ltmainrd4c,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "BLACK",
          fillOpacity: 0.5,
        });
        
         const first1rd = new maps.Polygon({
          paths: first1c,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "BLACK",
          fillOpacity: 0.5,
        });
        
         const secondxrd = new maps.Polygon({
          paths: secondxc,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "BLACK",
          fillOpacity: 0.5,
        });
        
        const thirdxrd = new maps.Polygon({
          paths: thirdxc,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "BLACK",
          fillOpacity: 0.5,
        });
        
        
         
        
        const leftrd11 = new maps.Polygon({
          paths: leftrd1,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "BLACK",
          fillOpacity: 0.5,
        });
         
        
         const park = new maps.Polygon({
          paths: parkcords,
          strokeColor: "GREEN",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "GREEN",
          fillOpacity: 0.5,
        });
        
        const park2 = new maps.Polygon({
          paths: park2c,
          strokeColor: "GREEN",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "GREEN",
          fillOpacity: 0.5,
        });
        
        
        
        const plot101 = new maps.Polygon({
          paths: plot101cords,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "ORANGE",
          fillOpacity: 0.5,
        });
        
        const plot100 = new maps.Polygon({
          paths: plot100cords,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "GREY",
          fillOpacity: 0.5,
        });
        
        const plot98 = new maps.Polygon({
          paths: plot98cords,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "ORANGE",
          fillOpacity: 0.5,
        });
        
        
        const plot99 = new maps.Polygon({
          paths: plot99cords,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: "ORANGE",
          fillOpacity: 0.5,
        });
        
        
        
        const line9692cs = [
        {lat: 17.039731, lng:  78.568974},
        {lat:17.039770, lng : 78.568603},
        ];
        
        
        const line9692 = new maps.Polyline({
          path: line9692cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 2,
        });
        
        line9692.setMap(map);
        
        const line96921cs = [
        {lat: 17.039890, lng:  78.568763},
        {lat:17.039613, lng : 78.568736},
        ];
        
        
        const line96921 = new maps.Polyline({
          path: line96921cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 2,
        });
        
        line96921.setMap(map);
        
        const line96922cs = [
        {lat: 17.039880, lng:  78.568879},
        {lat:17.039604, lng : 78.568847},
        ];
        
        
        const line96922 = new maps.Polyline({
          path: line96922cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 2,
        });
        
        line96922.setMap(map);
        
        const mark97 = new maps.Marker({
          position: { lat: 17.039845, lng:78.568688 },
        
        
          label: {text: "97", color: "white"},
           icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
           title: "45x50: 250 SQYDS "
        });
        
        const mark96 = new maps.Marker({
          position: { lat: 17.039837, lng:78.568811 },
        
          label: {text: "96", color: "white"},
           icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
           title: "45x50: 250 SQYDS "
        });
        const mark95 = new maps.Marker({
          position: { lat: 17.039829, lng:78.568918 },
          label: {text: "95", color: "white"},
           icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
           title: "45x50: 250 SQYDS "
        });
        
        
        const mark92 = new maps.Marker({
          position: { lat: 17.039683, lng:78.568634 },
         
          label: {text: "92", color: "white"},
           icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
           title: "45x50: 250 SQYDS "
        });
        
        const mark93 = new maps.Marker({
          position: { lat: 17.039667, lng:  78.568807 },
          label: {text: "93", color: "white"},
           icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
           title: "45x50: 250 SQYDS "
        });
        const mark94 = new maps.Marker({
          position: { lat: 17.039655, lng:78.568919 },
        
          label: {text: "94", color: "white"},
           icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
           title: "45x50: 250 SQYDS "
        });
        
        line96921.setMap(map);
        
        const line6350cs = [
        {lat: 17.039849, lng: 78.569227},
        {lat:17.038623, lng : 78.569141},
        ];
        
        
        const line6350 = new maps.Polyline({
          path: line6350cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        line6350.setMap(map);
        
        const line4928cs = [
        {lat: 17.039820, lng: 78.569600},
        {lat:17.038690, lng : 78.569515},
        ];
        
        
        const line4928 = new maps.Polyline({
          path: line4928cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        line4928.setMap(map);
        
        
        
        
        const mort1cs = [
        {lat: 17.039730, lng: 78.569814},
        {lat:17.039771, lng : 78.569904},
        
        
        
        ];
        
        
        const mort1 = new maps.Polyline({
          path: mort1cs,
           geodesic: true,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        });
        
        mort1.setMap(map);
        
        
        const mort2cs = [
        {lat: 17.0386771, lng: 78.569736},
        
        {lat:17.038766, lng : 78.570015},
        
        
        ];
        
        
        const mort2 = new maps.Polyline({
          path: mort2cs,
           geodesic: true,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        });
        
        mort2.setMap(map);
        
        
        const mort3cs = [
        {lat: 17.038816, lng: 78.569744},
        
        {lat:17.038938, lng : 78.570031},
        
        
        ];
        
        
        const mort3 = new maps.Polyline({
          path: mort3cs,
           geodesic: true,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        });
        
        mort3.setMap(map);
        
        
        
        const mort4cs = [
        {lat:17.038965, lng:78.569760},
        
        {lat:17.039122, lng : 78.570048},
        
        
        ];
        
        
        const mort4 = new maps.Polyline({
          path: mort4cs,
           geodesic: true,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        });
        
        mort4.setMap(map);
        
        
        const mortgageFlag = new maps.Marker({
          position: { lat: 17.039199, lng: 78.569891},
          
          label: {text: "Mortgaged", color: "WHITE"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 25,
            strokeWeight: 1,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
         title: "Mortgaged to HMDA",
          optimized: false,
        });
        
        
        
        mortgageFlag.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mortgageFlag.getTitle());
            infoWindow.open(mortgageFlag.getMap(), mortgageFlag);
        });
        
        
        
        const mort5cs = [
        {lat: 17.039143, lng:78.569776},
        
        {lat:17.039257, lng : 78.570056},
        
        
        ];
        
        
        const mort5 = new maps.Polyline({
          path: mort5cs,
           geodesic: true,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        });
        
        mort5.setMap(map);
        
        const mort6cs = [
        {lat: 17.039264, lng:78.569784},
        
        {lat:17.039391, lng : 78.570067},
        
        
        ];
        
        
        const mort6 = new maps.Polyline({
          path: mort6cs,
           geodesic: true,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        });
        
        mort6.setMap(map);
        
        
        const mort7cs = [
        {lat: 17.039435, lng: 78.569794},
        
        {lat:17.039560, lng : 78.569991},
        
        
        ];
        
        
        const mort7 = new maps.Polyline({
          path: mort7cs,
           geodesic: true,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        });
        
        mort7.setMap(map);
        
        
        
        const mort8cs = [
        {lat: 17.039591, lng: 78.569806},
        
        {lat: 17.039694, lng : 78.569938},
        
        ];
        
        
        const mort8 = new maps.Polyline({
          path: mort8cs,
           geodesic: true,
          strokeColor: "BLACK",
          strokeOpacity: 0.8,
          strokeWeight: 5,
        });
        
        mort8.setMap(map);
        
        
        
        const p50741cs = [
        {lat: 17.039740, lng: 78.569074},
        
        {lat: 17.039725, lng : 78.569374},
        
        ];
        
        
        const p50741 = new maps.Polyline({
          path: p50741cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p50741.setMap(map);
        
        
        const p50742cs = [
        {lat: 17.039630, lng: 78.569365},
        {lat: 17.039648, lng : 78.569065},
        ];
        
        
        const p50742 = new maps.Polyline({
          path: p50742cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p50742.setMap(map);
        
        
        const p50743cs = [
        {lat: 17.039556, lng: 78.569061},
        {lat: 17.039539, lng : 78.569355},
        ];
        
        
        const p50743 = new maps.Polyline({
          path: p50743cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p50743.setMap(map);
        
        
        const p50744cs = [
        {lat: 17.039448, lng: 78.569351},
        {lat: 17.039467, lng : 78.569052},
        ];
        
        
        const p50744 = new maps.Polyline({
          path: p50744cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p50744.setMap(map);
        
        
        const p50745cs = [
        {lat: 17.039376, lng: 78.569051},
        {lat: 17.039361, lng :78.569346},
        ];
        
        
        const p50745 = new maps.Polyline({
          path: p50745cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p50745.setMap(map);
        
        
        
        const p50746cs = [
        {lat: 17.039273, lng: 78.569336},
        {lat: 17.039286, lng :78.569040},
        ];
        
        
        const p50746 = new maps.Polyline({
          path: p50746cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p50746.setMap(map);
        
        
        const p50747cs = [
        {lat: 17.039196, lng: 78.569035},
        {lat: 17.039175, lng :78.569332},
        ];
        
        const p50747 = new maps.Polyline({
          path: p50747cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p50747.setMap(map);
        
        
        const p50748cs = [
        {lat: 17.039084, lng: 78.569324},
        {lat: 17.039103, lng :78.569025},
        ];
        
        const p50748 = new maps.Polyline({
          path: p50748cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p50748.setMap(map);
        
        
        const p50749cs = [
        {lat: 17.039017, lng: 78.569020},
        {lat: 17.038993, lng :78.569315},
        ];
        
        const p50749 = new maps.Polyline({
          path: p50749cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p50749.setMap(map);
        
        
        
        
        const p507410cs = [
        {lat: 17.038908, lng: 78.569307},
        {lat: 17.038930, lng :78.569012},
        ];
        
        const p507410 = new maps.Polyline({
          path: p507410cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p507410.setMap(map);
        
        
        const p507411cs = [
        {lat: 17.038835, lng: 78.569005},
        {lat: 17.038811, lng :78.569303},
        ];
        
        const p507411 = new maps.Polyline({
          path: p507411cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p507411.setMap(map);
        
        
        const p507412cs = [
        {lat: 17.038709, lng: 78.569299},
        {lat: 17.038719, lng :78.569157},
        ];
        
        const p507412 = new maps.Polyline({
          path: p507412cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p507412.setMap(map);
        
        
        
        
        const mark63 = new maps.Marker({
          position: { lat: 17.039811, lng: 78.569135 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "63", color: "white"},
          map: map,
          title: "45x50: 300 SQYDS"
        }); 
        
        
        mark63.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark63.getTitle());
            infoWindow.open(mark63.getMap(), mark63);
        });
        
        
        const mark62 = new maps.Marker({
          position: { lat: 17.039804, lng: 78.569304 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "62", color: "white"},
          map: map,
          title: "45x50: 300 SQYDS"
        }); 
        
        
        mark62.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark62.getTitle());
            infoWindow.open(mark62.getMap(), mark62);
        });
        
        
        
        const mark74 = new maps.Marker({
          position: { lat: 17.038727, lng: 78.569065 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "74", color: "white"},
          map: map,
          title: "45x50X70: 300 SQYDS"
        }); 
        
        
        mark74.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark74.getTitle());
            infoWindow.open(mark74.getMap(), mark74);
        });
        
        
        const mark50 = new maps.Marker({
          position: { lat: 17.038627, lng: 78.569217 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "50", color: "white"},
          map: map,
          title: "37x50X62: 280 SQYDS"
        }); 
        
        
        mark50.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark50.getTitle());
            infoWindow.open(mark50.getMap(), mark50);
        });
        
        
        const mark64 = new maps.Marker({
          position: { lat: 17.039688, lng: 78.569140 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "64", color: "white"},
          map: map,
          title: "33x50: 183 SQYDS"
        }); 
        
        
        mark64.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark64.getTitle());
            infoWindow.open(mark64.getMap(), mark64);
        });
        
        
        const mark61 = new maps.Marker({
          position: { lat: 17.039679, lng: 78.569290 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "61", color: "white"},
          map: map,
          title: "33x50: 183 SQYDS"
        }); 
        
        
        mark61.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark61.getTitle());
            infoWindow.open(mark61.getMap(), mark61);
        });
        
        
        const p28491cs = [
        {lat: 17.039717, lng: 78.569450},
        {lat: 17.039699, lng : 78.569732},
        ];
        
        const p28491 = new maps.Polyline({
          path: p28491cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p28491.setMap(map);
        
        
        const p28492cs = [
        {lat: 17.039622, lng: 78.569441},
        {lat: 17.039599, lng : 78.569726},
        ];
        
        const p28492 = new maps.Polyline({
          path: p28492cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p28492.setMap(map);
        
        
        const p28493cs = [
        {lat: 17.039532, lng: 78.569438},
        {lat: 17.039509, lng : 78.569719},
        ];
        
        const p28493 = new maps.Polyline({
          path: p28493cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p28493.setMap(map);
        
        
        
        const p28494cs = [
        {lat: 17.039444, lng: 78.569428},
        {lat: 17.039426, lng : 78.569721},
        ];
        
        const p28494 = new maps.Polyline({
          path: p28494cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p28494.setMap(map);
        
        
        const p28495cs = [
        {lat: 17.039351, lng: 78.569424},
        {lat: 17.039332, lng : 78.569707},
        ];
        
        const p28495 = new maps.Polyline({
          path: p28495cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p28495.setMap(map);
        
        const p28496cs = [
        {lat: 17.039267, lng: 78.569416},
        {lat: 17.039247, lng : 78.569704},
        ];
        
        const p28496 = new maps.Polyline({
          path: p28496cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p28496.setMap(map);
        
        
        
        const p28497cs = [
        {lat: 17.039168, lng: 78.569413},
        {lat: 17.039152, lng : 78.569691},
        ];
        
        const p28497 = new maps.Polyline({
          path: p28497cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p28497.setMap(map);
        
        
        const p28498cs = [
        {lat: 17.039080, lng: 78.569400},
        {lat: 17.039064, lng : 78.569690},
        ];
        
        const p28498 = new maps.Polyline({
          path: p28498cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p28498.setMap(map);
        
        const p28499cs = [
        {lat: 17.038991, lng: 78.569396},
        {lat: 17.038972, lng : 78.569679},
        ];
        
        const p28499 = new maps.Polyline({
          path: p28499cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p28499.setMap(map);
        
        
        const p284910cs = [
        {lat: 17.038888, lng: 78.569390},
        {lat: 17.038866, lng : 78.569676},
        ];
        
        const p284910 = new maps.Polyline({
          path: p284910cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p284910.setMap(map);
        
        
        const mark49 = new maps.Marker({
          position: { lat:17.038767, lng: 78.569427 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "49", color: "white"},
          map: map,
          title: "66x45: 330 SQYDS"
        }); 
        
        
        mark49.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark49.getTitle());
            infoWindow.open(mark49.getMap(), mark49);
        });
        
        
        const mark28 = new maps.Marker({
          position: { lat: 17.038755, lng:78.569595 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "28", color: "white"},
          map: map,
          title: "66x50: 366 SQYDS"
        }); 
        
        
        mark28.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark28.getTitle());
            infoWindow.open(mark28.getMap(), mark28);
        });
        
        
        
        const mark39 = new maps.Marker({
          position: { lat: 17.039777, lng:78.569510 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "39", color: "white"},
          map: map,
          title: "45x45: 225 SQYDS"
        }); 
        
        
        mark39.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark39.getTitle());
            infoWindow.open(mark39.getMap(), mark39);
        });
        
        const mark38 = new maps.Marker({
          position: { lat: 17.039768, lng:78.569650 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "38", color: "white"},
          map: map,
          title: "45x50: 250 SQYDS"
        }); 
        
        
        mark38.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark38.getTitle());
            infoWindow.open(mark38.getMap(), mark38);
        });
        
        
        const mark40 = new maps.Marker({
          position: { lat: 17.039679, lng:78.569484 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "40", color: "white"},
          map: map,
          title: "33x45: 165 SQYDS"
        }); 
        
        
        mark40.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark40.getTitle());
            infoWindow.open(mark40.getMap(), mark40);
        });
        
        
        
        const mark37 = new maps.Marker({
          position: { lat: 17.039669, lng:78.569657 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "37", color: "white"},
          map: map,
          title: "33x50: 183 SQYDS"
        }); 
        
        
        mark37.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark37.getTitle());
            infoWindow.open(mark37.getMap(), mark37);
        });
        
        
        const p27261cs = [
        {lat: 17.038608, lng: 78.569509},
        {lat: 17.038418, lng : 78.569493},
        ];
        
        const p27261 = new maps.Polyline({
          path: p27261cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p27261.setMap(map);
        
        
        const mark27 = new maps.Marker({
          position: { lat: 17.038536, lng:78.569421 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "27", color: "white"},
          map: map,
          title: "46x45X69: 300 SQYDS"
        }); 
        
        mark27.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark27.getTitle());
            infoWindow.open(mark27.getMap(), mark27);
        });
        
        const mark26 = new maps.Marker({
          position: { lat: 17.038471, lng:78.569573 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "26", color: "white"},
          map: map,
          title: "69X50X94: 700 SQYDS"
        }); 
        
        mark26.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark26.getTitle());
            infoWindow.open(mark26.getMap(), mark26);
        });
        
        
        const p75871cs = [
        {lat: 17.039535, lng: 78.568808},
        {lat: 17.038849, lng : 78.568760},
        ];
        
        const p75871 = new maps.Polyline({
          path: p75871cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p75871.setMap(map);
        
        
        const p20251cs = [
        {lat: 17.038584, lng: 78.569874},
        {lat: 17.038214, lng : 78.569843},
        ];
        
        const p20251 = new maps.Polyline({
          path: p20251cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p20251.setMap(map);
        
        
        
        const p88911cs = [
        {lat: 17.039142, lng: 78.568565},
        {lat: 17.039178, lng : 78.568375},
        ];
        
        const p88911 = new maps.Polyline({
          path: p88911cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p88911.setMap(map);
        
        
        const p88912cs = [
        {lat: 17.039229, lng: 78.568574},
        {lat: 17.039255, lng :78.568407},
        ];
        
        const p88912 = new maps.Polyline({
          path: p88912cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p88912.setMap(map);
        
        
        const p88913cs = [
        {lat: 17.039326, lng: 78.568578},
        {lat: 17.039353, lng :78.568445},
        ];
        
        const p88913 = new maps.Polyline({
          path: p88913cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p88913.setMap(map);
        
        
        const mark88 = new maps.Marker({
          position: { lat: 17.039101, lng:78.568448 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "88", color: "white"},
          map: map,
          title: "66X62: 300 SQYDS"
        }); 
        
        mark88.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark88.getTitle());
            infoWindow.open(mark88.getMap(), mark88);
        });
        
        
        const mark89 = new maps.Marker({
          position: { lat: 17.039215, lng:78.568493 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "89", color: "white"},
          map: map,
          title: "56X33: 205 SQYDS"
        }); 
        
        mark89.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark89.getTitle());
            infoWindow.open(mark89.getMap(), mark89);
        });
        
        
        const mark90 = new maps.Marker({
          position: { lat: 17.039289, lng:78.568493 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "90", color: "white"},
          map: map,
          title: "47X33: 172 SQYDS"
        }); 
        
        mark90.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark90.getTitle());
            infoWindow.open(mark90.getMap(), mark90);
        });
        
        
        const mark91 = new maps.Marker({
          position: { lat: 17.039464, lng:78.568540 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "91", color: "white"},
          map: map,
          title: "24X78: 208 SQYDS"
        }); 
        
        mark91.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark91.getTitle());
            infoWindow.open(mark91.getMap(), mark91);
        });
        
        const px75871cs = [
        {lat: 17.039411, lng: 78.568664},
        {lat: 17.039382, lng: 78.568951},
        ];
        
        const px75871 = new maps.Polyline({
          path: px75871cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        px75871.setMap(map);
        
        
        
        const px75872cs = [
        {lat: 17.039304, lng: 78.568942},
        {lat: 17.039329, lng: 78.568656},
        ];
        
        const px75872 = new maps.Polyline({
          path: px75872cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        px75872.setMap(map);
        
        const px75873cs = [
        {lat: 17.039232, lng: 78.568649},
        {lat: 17.039209, lng: 78.568938},
        ];
        
        const px75873 = new maps.Polyline({
          path: px75873cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        px75873.setMap(map);
        
        
        const px75874cs = [
        {lat: 17.039129, lng: 78.568926},
        {lat: 17.039151, lng: 78.568644},
        ];
        
        const px75874 = new maps.Polyline({
          path: px75874cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        px75874.setMap(map);
        
        
        const px75875cs = [
        {lat: 17.039051, lng: 78.568638},
        {lat: 17.039029, lng: 78.568923},
        ];
        
        const px75875 = new maps.Polyline({
          path: px75875cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        px75875.setMap(map);
        
        
        const px75876cs = [
        {lat: 17.038938, lng: 78.568912},
        {lat: 17.038944, lng: 78.568771},
        ];
        
        const px75876 = new maps.Polyline({
          path: px75876cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        px75876.setMap(map);
        
        
        const p16191cs = [
        {lat: 17.038838, lng: 78.570295},
        {lat: 17.038846, lng: 78.570099},
        ];
        
        const p16191 = new maps.Polyline({
          path: p16191cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p16191.setMap(map);
        
        
        const p16192cs = [
        {lat: 17.038939, lng: 78.570112},
        {lat: 17.038935, lng:  78.570257},
        ];
        
        const p16192 = new maps.Polyline({
          path: p16192cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p16192.setMap(map);
        
        
        
        const p16193cs = [
        {lat: 17.038638, lng: 78.570234},
        {lat: 17.038844, lng:  78.570231},
        ];
        
        const p16193 = new maps.Polyline({
          path: p16193cs,
           geodesic: true,
          strokeColor: "WHITE",
          strokeOpacity: 0.8,
          strokeWeight: 3,
        });
        
        p16193.setMap(map);
        kurmiddalayout.setMap(map);
        kumiddamnrd.setMap(map);
        rtmainrd.setMap(map);
        leftrd11.setMap(map);
        first1rd.setMap(map);
        secondxrd.setMap(map);
        thirdxrd.setMap(map);
        ltmainrd2.setMap(map);
        ltmainrd3.setMap(map);
        ltmainrd4.setMap(map);
        
        
        
        park.setMap(map);
        park2.setMap(map);
        plot101.setMap(map);
        plot100.setMap(map);
        plot98.setMap(map);
        plot99.setMap(map);
        
        
        
        const image =
          "https://developers.com/maps/documentation/javascript/examples/full/images/beachflag.png";
        
        
        
        const mainFlag1 = new maps.Marker({
          position: {lat: 17.040349, lng: 78.568842},
          map,
          icon: {image},
        });
        
        
        
        
        const mainFlag2 = new maps.Marker({
          position: {lat: 17.039901, lng: 78.569857},
          map,
          icon: {image},
        });
        
        const mainFlag3 = new maps.Marker({
          position: {lat: 17.038629, lng: 78.570385},
          map,
          icon: {image},
        });
        
        const mainFlag4 = new maps.Marker({
          position: {lat: 17.038499, lng: 78.570696},
          map,
          icon: {image},
        });
        
        const mainFlag5 = new maps.Marker({
          position: {lat: 17.038341, lng: 78.570534},
          map,
          icon: {image},
        });
        
        const mainFlag6 = new maps.Marker({
          position: {lat: 17.038108, lng: 78.570038},
          map,
          icon: {image},
        });
        
        const mainFlag7 = new maps.Marker({
          position: {lat: 17.039087, lng: 78.568342},
          map,
          icon: {image},
        });
        
        
        const welcomeFlag = new maps.Marker({
          position: { lat: 17.039668, lng: 78.573642 },
          map,
          icon: {image},
        });
        
        
        const welcomeFlag2 = new maps.Marker({
          position: { lat: 17.039665, lng: 78.572561 },
          map,
          icon: {image},
        });
        
        
        const welcomeFlag3 = new maps.Marker({
          position: { lat: 17.039791, lng: 78.571747 },
          map,
          icon: {image},
        });
        
        
        const welcomeFlag4 = new maps.Marker({
          position: { lat: 17.039799, lng: 78.570946 },
          map,
          icon: {image},
        });
        
        const markPark = new maps.Marker({
          position: { lat: 17.040039, lng:78.569336 },
          label: {text: "Park", color: "white"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 14,
            strokeWeight: 2,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
           title: "Park Area"
        });
        
        const markPark2 = new maps.Marker({
          position: { lat: 17.038413, lng:78.570232 },
          label: {text: "Park", color: "white"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 14,
            strokeWeight: 2,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
           title: "Park Area"
        });
        
        const mark101 = new maps.Marker({
          position: { lat: 17.040262, lng:78.568900 },
          label: {text: "101", color: "white"},
           icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
           title: "60x80: 533 SQYDS. Sold out "
        });
        
        mark101.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark101.getTitle());
            infoWindow.open(mark101.getMap(), mark101);
        });
        
        const mark100 = new maps.Marker({
          position: { lat: 17.040165, lng: 78.568876 },
        
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          label: {text: "100", color: "white"},
          map: map,
          title: "35x90: 350 SQYDS"
        }); 
        
        
        mark100.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark100.getTitle());
            infoWindow.open(mark100.getMap(), mark100);
        });
        
        const mark99 = new maps.Marker({
          position: { lat: 17.040030, lng:78.568908 },
        
          label: {text: "99", color: "white"},
           icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
          title: "45X45:225 SQYDS. Sold Out"
        }); 
        
        mark99.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(mark99.getTitle());
            infoWindow.open(mark99.getMap(), mark99);
        });
        
        
        const mark98 = new maps.Marker({
          position: { lat: 17.040052, lng:78.568766},
          label: {text: "98", color: "white"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 10,
            strokeWeight: 4,
            fillColor: 'RED',
            strokeColor: 'RED',
            fillOpacity: 1
          },
          map: map,
          title: "45X50:250 SQYDS. Sold Out"
        });
        
        const main40ft = new maps.Marker({
          position: { lat: 17.039898, lng:78.569230},
          label: {text: "40ft", color: "red"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 12,
            strokeWeight: 1,
            fillColor: 'WHITE',
            strokeColor: 'WHITE',
            fillOpacity: 1
          },
          map: map,
         title: "40ft wide road",
          optimized: false,
        });
        
        
        
        main40ft.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(main40ft.getTitle());
            infoWindow.open(main40ft.getMap(), main40ft);
        });
        
        
        const left40ft = new maps.Marker({
          position: { lat: 17.039193, lng:78.568973},
          label: {text: "40ft", color: "red"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 12,
            strokeWeight: 2,
            fillColor: 'WHITE',
            strokeColor: 'WHITE',
            fillOpacity: 1
          },
          map: map,
          title: "40ft wide road",
          optimized: false,
        });
        
        
        
        left40ft.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(left40ft.getTitle());
            infoWindow.open(left40ft.getMap(), left40ft);
        })
        
        const left240ft = new maps.Marker({
          position: { lat: 17.039273, lng: 78.569374},
          label: {text: "40ft", color: "red"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 12,
            strokeWeight: 2,
            fillColor: 'WHITE',
            strokeColor: 'WHITE',
            fillOpacity: 1
          },
          map: map,
          title: "40ft wide road",
          optimized: false,
        });
        
        
        
        left240ft.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(left240ft.getTitle());
            infoWindow.open(left240ft.getMap(), left240ft);
        })
        
        
        const left340ft = new maps.Marker({
          position: { lat: 17.039097, lng: 78.569726},
          label: {text: "40ft", color: "red"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 12,
            strokeWeight: 2,
            fillColor: 'WHITE',
            strokeColor: 'WHITE',
            fillOpacity: 1
          },
          map: map,
          title: "40ft wide road",
          optimized: false,
        });
        
        
        
        left340ft.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(left340ft.getTitle());
            infoWindow.open(left340ft.getMap(), left340ft);
        })
        
        
        const left440ft = new maps.Marker({
          position: { lat: 17.038923, lng: 78.570069},
          label: {text: "40ft", color: "red"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 12,
            strokeWeight: 2,
            fillColor: 'WHITE',
            strokeColor: 'WHITE',
            fillOpacity: 1
          },
          map: map,
          title: "40ft wide road",
          optimized: false,
        });
        
        
        
        left440ft.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(left440ft.getTitle());
            infoWindow.open(left440ft.getMap(), left440ft);
        })
        
        
        const left133ft = new maps.Marker({
          position: { lat: 17.039233, lng: 78.568606},
          label: {text: "33ft", color: "red"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 12,
            strokeWeight: 2,
            fillColor: 'WHITE',
            strokeColor: 'WHITE',
            fillOpacity: 1
          },
          map: map,
          title: "33ft wide road",
          optimized: false,
        });
        
        
        
        left133ft.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(left133ft.getTitle());
            infoWindow.open(left133ft.getMap(), left133ft);
        })
        
          const left333ft = new maps.Marker({
          position: { lat:  17.038624, lng: 78.569866},
          label: {text: "33ft", color: "red"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 12,
            strokeWeight: 2,
            fillColor: 'WHITE',
            strokeColor: 'WHITE',
            fillOpacity: 1
          },
          map: map,
          title: "33ft wide road",
          optimized: false,
        });
        
        
        left333ft.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(left333ft.getTitle());
            infoWindow.open(left333ft.getMap(), left333ft);
        })
        
        
        const left233ft = new maps.Marker({
          position: { lat:  17.039568, lng: 78.568789},
          label: {text: "33ft", color: "red"},
          icon: {
            path: maps.SymbolPath.CIRCLE,
            scale: 12,
            strokeWeight: 2,
            fillColor: 'WHITE',
            strokeColor: 'WHITE',
            fillOpacity: 1
          },
          map: map,
          title: "33ft wide road",
          optimized: false,
        });
        
        
        
        left233ft.addListener("click", () => {
        
            infoWindow.close();
            infoWindow.setContent(left233ft.getTitle());
            infoWindow.open(left233ft.getMap(), left233ft);
        })
        
        
        
        
          
        
        
        
          const themes = {
            light: {
              sidebar: {
                backgroundColor: "#ffffff",
                color: "#607489",
              },
              menu: {
                menuContent: "#fbfcfd",
                icon: "#0098e5",
                hover: {
                  backgroundColor: "#c5e4ff",
                  color: "#44596e",
                },
                disabled: {
                  color: "#9fb6cf",
                },
              },
            },
            dark: {
              sidebar: {
                backgroundColor: "#0b2948",
                color: "#8ba1b7",
              },
              menu: {
                menuContent: "#082440",
                icon: "#59d0ff",
                hover: {
                  backgroundColor: "#00458b",
                  color: "#b6c8d9",
                },
                disabled: {
                  color: "#3e5e7e",
                },
              },
            },
          };
    
          //TO HERE
      };


      
      return (
        <div>
          <div style={{ height: "100vh", width: "100%",  position: "inherit", overflow:"hidden", margin: "0 auto" }}>
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyB-IhLNuNEMTKd8VHG8Xyij2srPNVS_PR4",
                libraries: ["places", "geometry","visualization"],
              }}
              
              defaultCenter={{ lat: 17.040349, lng: 78.568842 }}
              defaultZoom={10}
            //   center={this.state.currentLocation}
              center={{ lat: 17.040349, lng: 78.568842 }}
              yesIWantToUseGoogleMapApiInternals
              //onGoogleApiLoaded={({ map, maps }) => renderPolygon(map, maps)} 
              onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
              options={{
                styles: [ { featureType: "all", elementType: "labels", stylers: [ { visibility:  "on", } ] } ],
                mapTypeControl: false,
                mapTypeId: "hybrid",
                gestureHandling: "greedy",
                mapTypeControl: false,

                streetViewControl: false,
      
                zoomControl: false,
      
                fullscreenControl: true,
      
                rotateControl: true,
      
                disableDefaultUI: false,
      
                panControl: true,
      
                scaleControl: true,
      
                streetViewControl: true,
      
                overviewMapControl: true,
              }}
            />
          </div>
        </div>
      );
    //} //uncomment
  }

  export default GoogleMaps;